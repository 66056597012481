import React from "react";
import { Switch, Redirect, Route, BrowserRouter } from "react-router-dom";
import { Home } from "./views/index";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route exact path="/home" component={Home} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
