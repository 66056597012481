import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnchorLink from 'react-anchor-link-smooth-scroll'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    // borderRadius: 0,
    // fontSize: 50,
    minWidth: 175,
    "&:hover": {
      border: "4px solid"
    },
  },
  buttonText: {
    fontSize: 20
  },
  link: {
    textDecoration: 'none'
  }
}));

export const NavbarButton = (props) => {
  const classes = useStyles();

  const { buttonText } = props;

  return (
    <>
      <AnchorLink href={`#${props.link}`} className={classes.link}>
        <Button variant="text" color="primary" className={classes.button}>
          <Typography variant="h5" className={classes.buttonText}>{buttonText}</Typography>
        </Button>
      </AnchorLink>
    </>
  );
};

export default NavbarButton;
