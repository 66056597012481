import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Box, Typography, Divider } from "@material-ui/core";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  homeBody: {
    display: "block",
    background: "#1d2731",
    width: "385px",
    margin: "40px auto 15px",
    padding: "0 0 70px",
    boxShadow: "0 4px 10px 4px #1d2731",
    textAlign: "center",
  },
  largeLogo: {
    marginTop: "30px",
    display: "inline-block",
    width: "200px",
  },
  subtitleText: {
    color: "#FFFFFF",
    margin: "20px",
  },
  inputField: {
    width: "300px",
    marginBottom: "20px",
  },
  inputFieldLarge: {
    width: "620px",
    marginBottom: "20px",
  },
  cssLabel: {
    color: "#FFFFFF",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  cssFocused: {},

  notchedOutline: {
    borderWidth: "1px",
    borderColor: `${theme.palette.secondary.main} !important`,
  },
  paragraphText: {
    textAlign: "center",
    color: "#FFFFFF",
  },
  link: {
    color: "#d9b310",
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "-0.05px",
    color: "#FFFFFF",
    lineHeight: 1.7
  },
  title: {
    fontSize: 50,
    marginBottom: 20,
    paddingTop: 40,
    lineHeight: 1.4
  },
  divider: {
    background: `${theme.palette.secondary.main} !important`,
  },
  contactBox: {
    display: "flex",
    flexWrap: "wrap"
  },
  contactInfoBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: 10,
    marginBottom: 40
  },
  contactIcon: {
    color: `${theme.palette.secondary.main} !important`,
    marginRight: 20,
  },
  socialMediaContact: {
    color: `${theme.palette.secondary.main} !important`,
    marginTop: 10,
    "&:hover": {
      cursor: "pointer"
    },
  }
}));

export const Contact = (props) => {
  // const ref = useRef(null);
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");

  // const handleError = () => {
  //   ref.current.handleError();
  // };

  const loginUser = async (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Box>
        <Box m={2}>
          <Typography color="primary" align="center" variant="h2" className={classes.title}>
            WE'D LOVE TO GET IN TOUCH
          </Typography>
          <Typography align="center" className={classes.subtitle}>
            Contact us for a consultation or more information
          </Typography>
        </Box>
        <Box ml={10} mr={10}>
          <Divider variant="middle" className={classes.divider} />
        </Box>
        <Box className={classes.contactBox} justifyContent="center">
          <Box m={2} textAlign="center" style={{ maxWidth: 500 }}>
            <Box className={classes.contactInfoBox}>
                <LocationOnOutlinedIcon className={classes.contactIcon}/>
                <Typography variant="h4">Eagan, MN</Typography>
            </Box>
            <Box className={classes.contactInfoBox}>
                <CallOutlinedIcon className={classes.contactIcon}/>
                <Typography variant="h4">(507) 340-3793</Typography>
            </Box>
            <Box className={classes.contactInfoBox}>
                <EmailOutlinedIcon className={classes.contactIcon}/>
                <Typography variant="h4">jeff@integrisphilanthropy.com</Typography>
            </Box>
            <Divider variant="middle" className={classes.divider} />
            <center><LinkedInIcon onClick={e =>  window.location.href='https://www.linkedin.com/in/jeff-halbur-58431aa'} fontSize="large" className={classes.socialMediaContact}/></center>
          </Box>
          {/* <Box m={2} textAlign="center" style={{ maxWidth: 1000 }}>
            <form className={classes.form} onSubmit={loginUser}>
              <Box>
                <TextField
                    required={true}
                    label="Name"
                    variant="outlined"
                    color="primary"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={classes.inputField}
                    style={{marginRight: 20}}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      inputMode: "numeric",
                    }}
                  />
                  <TextField
                    required={true}
                    label="Email"
                    variant="outlined"
                    color="primary"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={classes.inputField}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      inputMode: "numeric",
                    }}
                  />
              </Box>
              <Box>
                <TextField
                  required={true}
                  label="Subject"
                  variant="outlined"
                  color="primary"
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className={classes.inputFieldLarge}
                  style={{maxWidth: 700}}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    inputMode: "numeric",
                  }}
                />
              </Box>
              <Box>
                <TextField
                  required={true}
                  label="Message"
                  variant="outlined"
                  color="primary"
                  type="text"
                  multiline
                  rows={5}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className={classes.inputFieldLarge}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    inputMode: "numeric",
                  }}
                />
              </Box>
              <Button color="primary" size="large" variant="contained" className={classes.demoButton} type="submit">
                SEND
              </Button>
            </form>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default Contact;
