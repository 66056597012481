import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import topImage from "./../../resources/topImageDark.jpg";
import { Typography, Box } from "@material-ui/core";
import { FullsizePicture } from "react-responsive-picture";
import { Scrollbars } from 'react-custom-scrollbars';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  buttonText: {
    borderRadius: 0,
    "&:hover": {
      borderBottom: " 3px solid",
    },
  },
  topImage: {
    height: 600,
  },
  imageText: {
    position: "relative",
    textAlign: "center",
    color: "white",
    margin: "auto",
    width: "100%",
    padding: 10,
    marginTop: 120
  },
  heading: {
    paddingTop: 40,
    paddingBottom: 20,
    fontSize: 50,
    lineHeight: 1.7
  },
  bodyText: {
      color: `${theme.palette.white} !important`,
      maxWidth: 1000,
      margin: "0 auto",
      paddingBottom: 60,
      fontSize: 20,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.7,
  }
}));

export const NavbarButton = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.topImage}>
        <FullsizePicture src={topImage}>
          <Box className={classes.imageText}>
            <Scrollbars className={classes.bodyText} style={{height: 400, width: "100%"}}>
              <Typography color="primary" align="center" variant="h2" className={classes.heading}>
                  FOUNDING PRINCIPLE
              </Typography>
                <Typography align="center" variant="body1" className={classes.bodyText}>
                    Integris Philanthropy Advisors, LLC is guided by the belief that trust and integrity are the foundation of all successful 
                    relationships. We embody this principle in everything we do for our clients, whether it’s consulting on business 
                    and fundraising strategy for non-profit organizations or providing training and coaching services.
                </Typography>
            </Scrollbars>
          </Box>
        </FullsizePicture>
      </Box>
    </>
  );
};

export default NavbarButton;
