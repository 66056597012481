import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textBox: {
    textAlign: "center",
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  heading: {
    paddingTop: 40,
    paddingBottom: 20,
    fontSize: 50,
    lineHeight: 1.4
  },
  bodyText: {
      color: `${theme.palette.white} !important`,
      maxWidth: 1000,
      margin: "0 auto",
      paddingBottom: 60,
      fontSize: 20,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.7
  },
  itemContainer: {
    margin: "auto",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20
  },
  review: {
    width: 250,
    height: 700,
    margin: 40,
    marginTop: 10,
    marginBottom: 10
  },
  reviewBody: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    fontSize: 20,
    color: "white",
    lineHeight: 1.3
  },
  reviewer: {
    maxWidth: 700,
    margin: "0 auto",
    paddingBottom: 40,
    fontSize: 20,
    paddingLeft: 20,
    paddingRight: 20, 
    lineHeight: 1.7,
    fontWeight: "bold"
  },
}));

const Review = (props) => {
    const classes = useStyles();
  
    return (
      <>
        <Box className={classes.review}>
          <Typography className={classes.reviewBody} variant="h2">{props.reviewBody}</Typography>
          <Typography className={classes.reviewer} variant="h2">{props.reviewer}</Typography>
        </Box>
      </>
    );
  };

export const Clients = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.textBox}>
        <Typography color="primary" align="center" variant="h2" className={classes.heading}>
            TESTIMONIALS
        </Typography>
        <Box className={classes.itemContainer} display="flex" flexWrap="wrap">
            <Review reviewer={"Matt Gill, Sr. Donor Advisor - Greater Twin Cities United Way"} reviewBody={"\"I've served with leaders in the military and nonprofit industry for 20+ years, and Jeff was the best boss I ever had.  He has unparalleled ability to connect with empathy, to build trust quickly, and drive toward a vision.  He could draw out the strengths of even the most reluctant donor or employee, inspiring everyone around him to be the best version of themselves.\""}/>
            <Review reviewer={"Kim Stone, Vice President of Advancement and External Relations, Women's Foundation of Minnesota"} reviewBody={"\"Jeff is an exceptional leader, team builder, and communicator. He is donor centered in his approach and utilizes data to inform his strategy to achieve desired results. He is passionate about everything he does and is especially skilled at developing and cultivating trusting and engaging relationships. He is a proven advancement professional who leads with integrity.\""}/>
            <Review reviewer={"Robert S Poferl Retired Executive Leader"} reviewBody={"\"Jeff is a trusted colleague and friend. I’ve always been impressed with his passion for helping people, and watched him recruit and develop a high-performing team during our time together. Jeff's success is due to his authenticity and belief in people. The non-profit community is fortunate to have him involved.\""}/>
            <Review reviewer={"Pace Winkels, Manager of National Advancement at Artspace"} reviewBody={"\"As a newcomer to my career, Jeff illuminated a very clear and structured path to individual gift management and donor relations. He leads by example as he prioritizes building stronger relationships through individual communication with our organization's supporters. Jeff's humanizing approach to fundraising inspired me to strive to connect with our donors on a personal level.\""}/>
        </Box>
      </Box>
    </>
  );
};

export default Clients;