import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import ChatIcon from '@material-ui/icons/Chat';
import Grow from '@material-ui/core/Grow';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleIcon from '@material-ui/icons/People';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { Scrollbars } from 'react-custom-scrollbars';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textBox: {
    width: "100%",
    textAlign: "center",
    padding: 10
  },
  bodyText: {
      color: `${theme.palette.white} !important`,
      maxWidth: 1000,
      margin: "0 auto",
      paddingBottom: 40,
      fontSize: 16,
      paddingLeft: 20,
      paddingRight: 20
  },
  item: {
    width: 350,
    height: 300,
    backgroundColor: "#1d2731",
    margin: 10,
    borderRadius: 15,
    "&:hover": {
      filter: `brightness(85%)`,
      cursor: "pointer"
    },
  },
  heading: {
    paddingTop: 20,
    fontSize: 50,
    lineHeight: 1.7
  },
  itemIcon: {
    width: 100,
    height: 100,
    margin: 20,
    color: "white"
  },
  itemContainer: {
    margin: "auto",
    width: "100%",
    maxWidth: 1350,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20
  },
  itemTitle: {
    marginLeft: 10,
    marginRight: 10
  },
  itemBodyText: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    fontSize: 20,
    color: "white",
    lineHeight: 1.3
  }
}));

const Item = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    expanded ? setExpanded(false) : setExpanded(true)
  }

  return (
    <>
      <Box className={classes.item} onClick={handleClick}>
        {props.icon}
        <Typography className={classes.itemTitle} variant="h2">{props.title}</Typography>
        <Grow in={expanded} style={{backgroundColor: "#1d2731", width: "100%", height: "100%", position: "relative", left: 0, top: props.offset, borderRadius: 15, overflowY: "auto"}}>
          <Box>
            <Scrollbars>
              <Typography className={classes.itemTitle} variant="h2" style={{paddingTop: 20, paddingBottom: 20}}>{props.title}</Typography>
              <Typography className={classes.itemBodyText} variant="body1">{props.body}</Typography>
            </Scrollbars>
          </Box>
        </Grow>
      </Box>
    </>
  );
};

export const WhatWeDo = (props) => {
  const classes = useStyles();

  // const [selected, setSelected] = useState("item1");

  // const onSelect = key => {
  //   setSelected(key);
  // };

  return (
    <>
      <Box className={classes.textBox}>
        <Typography variant="h1" className={classes.heading}>WHAT WE DO</Typography>
        <Box className={classes.itemContainer} display="flex" flexWrap="wrap">
          <Item title={"Coaching"} offset={-177} body={"While some organizations have an Executive Director that serves as Chief Fundraising Officer, others use teams of dedicated fundraisers to get the job done.  No matter your organization’s structure, we can help mentor your team to effectively prospect new donors, build relationships, and develop strategies."} icon={<EmojiObjectsIcon className={classes.itemIcon}/>}/>
          <Item title={"Board Development & Governance"} offset={-209} body={"The best non-profits are defined by strong, highly engaged boards that determine the organization’s mission and vision. With capable board members, your organization will grow and thrive, accomplishing its goals and serving as many people as possible. Together we will help align your organization’s hierarchy from the board to executive leadership and frontline staff. From talent acquisition to onboarding new members, we strive to make each board member’s experience meaningful and impactful, allowing your organization to thrive in the process."} icon={<PeopleIcon className={classes.itemIcon}/>}/>
          <Item title={"Individual Donor Services"} offset={-177} body={"You have philanthropic goals and want to make a difference in the nonprofit sector, but perhaps you’re not certain how to go about selecting the most effective nonprofit to help you carry out your vision.  Integris Philanthropy Advisors can help by vetting nonprofits within the specific sector.  We’ll conduct a comparison of up to 5 nonprofits that you select and provide you with an impactful report to assist you in making an informed decision."} icon={<ChatIcon className={classes.itemIcon}/>}/>
          <Item title={"Strategic Planning & Goal Setting"} offset={-209} body={"Whether you are a small non-profit or have a multi-million-dollar annual budget, strategic planning and goal setting are critical to your organization’s overall success. We will identify your organization’s goals, align them to your mission, and build a roadmap to track progress, gather feedback, and ensure everyone is working together effectively."} icon={<BusinessCenterIcon className={classes.itemIcon}/>}/>
          <Item title={"Pre-campaign Planning"} offset={-177} body={"Determining whether your organization is prepared to launch a campaign can be daunting. Together, we can help identify the best times to start fundraising campaigns with structured, coordinated approaches tailored to your organization. By planning ahead, you and your staff can focus on getting the job done without any guesswork."} icon={<DateRangeIcon className={classes.itemIcon}/>}/>
          <Item title={"Fundraising Strategy"} offset={-177} body={"If there is one thing 2020 has taught non-profits, it is that revenue diversification is critical to the long-term health and survival of your organization.  By analyzing your organization’s mission, goals, and resources, and funding needs, we will help you expand your donor base and revitalize your grant writing and fundraising strategies. Is your non-profit organization accomplishing its goals? If you’d like to learn more about how we can help, contact us today."} icon={<MonetizationOnIcon className={classes.itemIcon}/>}/>
        </Box>
      </Box>
    </>
  );
};

export default WhatWeDo;