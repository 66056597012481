import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textBox: {
    textAlign: "center",
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  heading: {
    paddingTop: 40,
    paddingBottom: 20,
    fontSize: 50,
    lineHeight: 1.7
  },
  bodyText: {
      color: `${theme.palette.white} !important`,
      maxWidth: 1000,
      margin: "0 auto",
      paddingBottom: 60,
      fontSize: 20,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.7
  }
}));

export const Vision = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.textBox}>
        <Typography color="primary" align="center" variant="h2" className={classes.heading}>
            VISION
        </Typography>
        <Typography align="center" variant="body1" className={classes.bodyText}>
            Integris envisions a world where all non-profit organizations operate at peak 
            performance. By extension, non-profits who reach their full potential can 
            better serve their causes and the people, partners, and communities reliant upon them.      
        </Typography>
      </Box>
    </>
  );
};

export default Vision;