import React, { useState } from "react";
import { Typography, Box, IconButton, Toolbar, AppBar, Collapse, Hidden } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NavbarButton from "./NavbarButton";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../resources/logo.png"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  navbarButton: {
    marginRight: 20,
  },
  logo: {
    width: 300,
    padding: 10,
  }
}));

export const Navbar = () => {
  const classes = useStyles();

  const [drawerState, setDrawerState] = useState(false);

  function openDrawer() {
    setDrawerState(true);
  }

  function closeDrawer() {
    setDrawerState(false);
  }

  return (
    <>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Typography variant="h3" className={classes.title}>
            {/* <img className={classes.logo} src={logo} alt="Logo" /> */}
          </Typography>
          <Hidden mdDown>
          <NavbarButton buttonText={"Home"} link={'home'} className={classes.navbarButton} />
            <NavbarButton buttonText={"What we do"} link={'whatWeDo'} className={classes.navbarButton} />
            <NavbarButton buttonText={"Testimonials"} link={'testimonials'} className={classes.navbarButton} />
            <NavbarButton buttonText={"Meet Jeff"} link={'meetJeff'} className={classes.navbarButton} />
            <NavbarButton buttonText={"Contact"} link={'contact'} className={classes.navbarButton} />
          </Hidden>
          <Hidden lgUp>
            <div className="font-icon-wrapper" onClick={() => (drawerState ? closeDrawer() : openDrawer())}>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" size="medium">
                <MenuIcon />
              </IconButton>
            </div>
          </Hidden>
        </Toolbar>
        <Hidden lgUp>
          <Collapse in={drawerState}>
            <Box m={1}>
              <Box mb={1}>
                <NavbarButton buttonText={"Home"} link={'home'} className={classes.navbarButton} display="block" />
              </Box>
              <Box mb={1}>
                <NavbarButton buttonText={"What we do"} link={'whatWeDo'} className={classes.navbarButton} display="block" />
              </Box>
              <Box mb={1}>
                <NavbarButton buttonText={"Testimonials"} link={'testimonials'} className={classes.navbarButton} display="block" />
              </Box>
              <Box mb={1}>
                <NavbarButton buttonText={"Meet jeff"} link={'meetJeff'} className={classes.navbarButton} display="block" />
              </Box>
              <Box>
                <NavbarButton buttonText={"Contact"} link={'contact'} className={classes.navbarButton} display="block" />
              </Box>
            </Box>
          </Collapse>
        </Hidden>
      </AppBar>
    </>
  );
};

export default Navbar;
