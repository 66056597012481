import React from "react";
import Navbar from "./components/Navbar";
// import { makeStyles } from "@material-ui/core/styles";
import TopImage from "./components/TopImage";
import Contact from "./components/Contact";
import Mission from "./components/Mission";
import WhatWeDo from "./components/WhatWeDo";
import MeetJeff from "./components/MeetJeff";
import Vision from "./components/Vision";
import Clients from "./components/Clients";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   homeBody: {
//     display: "block",
//     minHeight: "85vh",
//     margin: "10px 10px auto",
//     paddingTop: "100px",
//     textAlign: "center",
//     backgroundColor: "#1d2731",
//   },
//   titleText: {
//     marginBottom: "15px",
//     lineHeight: "70px",
//   },
//   subtitleText: {
//     lineHeight: "50px",
//     color: "#FFFFFF",
//   },
//   demoButton: {
//     margin: "15px",
//   },
// }));

export const Home = () => {
  // const classes = useStyles();

  return (
    <>
      <section id='home'>
        <Navbar />
      </section>
      <TopImage />
      <Vision />
      <section id='whatWeDo'>
        <WhatWeDo />
      </section>
      <section id='testimonials'>
        <Clients />
      </section>
      <section id='meetJeff'>
        <MeetJeff />
      </section>
      <Mission/>
      <section id='contact'>
        <Contact />
      </section>
    </>
  );
};

export default Home;
