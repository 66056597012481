import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textBox: {
    textAlign: "center",
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  heading: {
    paddingTop: 40,
    paddingBottom: 20,
    fontSize: 50,
    lineHeight: 1.7
  },
  bodyText: {
      color: `${theme.palette.white} !important`,
      maxWidth: 1000,
      margin: "0 auto",
      paddingBottom: 60,
      fontSize: 20,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.7
  }
}));

export const Mission = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.textBox}>
        <Typography color="primary" align="center" variant="h2" className={classes.heading}>
            MISSION
        </Typography>
        <Typography align="center" variant="body1" className={classes.bodyText}>
        Our mission is to strengthen and support non-profit organizations by maximizing
        their productivity and enhancing their philanthropic performance through short-
        and long-term consulting services.      
        </Typography>
      </Box>
    </>
  );
};

export default Mission;
