import React from "react";
import Routes from "./Routes";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme/index";
import CssBaseline from "@material-ui/core/CssBaseline";

function App() {
  document.title = "Integris Philanthropy";
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
