import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Collapse, Button } from "@material-ui/core";
import { Picture } from "react-responsive-picture";
import portrait from "../../resources/jeffPortrait.jpg"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textBox: {
    textAlign: "center",
  },
  heading: {
    paddingTop: 40,
    paddingBottom: 30,
    fontSize: 50
  },
  bodyText: {
      color: `${theme.palette.white} !important`,
      maxWidth: 700,
      margin: "0 auto",
      paddingBottom: 20,
      fontSize: 20,
      paddingLeft: 20,
      paddingRight: 20, 
      paddingTop: 20,
      lineHeight: 1.7
  },
  moreBodyText: {
    color: `${theme.palette.white} !important`,
    maxWidth: 700,
    margin: "0 auto",
    paddingBottom: 40,
    fontSize: 20,
    paddingLeft: 20,
    paddingRight: 20, 
    lineHeight: 1.7
},
moreBodyTextQuote: {
  maxWidth: 700,
  margin: "0 auto",
  paddingBottom: 40,
  fontSize: 20,
  paddingLeft: 20,
  paddingRight: 20, 
  lineHeight: 1.7,
  fontWeight: "bold"
},
  image: {
    width: "30%",
    minWidth: 350,
    minHeight: 350,
    marginBottom: 0
  },
  button: {
    backgroundColor: `${theme.palette.secondary} !important`,
    marginBottom: 20
  },
  photoCredit: {
    fontSize: 12,
    marginTop: 0,
    color: '#808080'
  }
}));

export const MeetJeff = (props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    expanded ? setExpanded(false) : setExpanded(true)
  }

  return (
    <>
      <Box className={classes.textBox}>
        <Typography color="primary" align="center" variant="h2" className={classes.heading}>
            MEET JEFF
        </Typography>
        <Picture src={portrait} className={classes.image}/>
        <Typography className={classes.photoCredit}>Photo by Kristin Tangen</Typography>
        <Typography align="center" variant="body1" className={classes.bodyText}>
            With over 34 years of leadership experience – the past 16 years in the non-profit sector – Jeff Halbur
            brings a confident yet humble approach to mentoring and coaching. 
        </Typography>
        <Collapse in={expanded}>
          <Typography align="center" variant="body1" className={classes.moreBodyText}>
              Growing up on a farm in rural 
              Minnesota, Jeff learned the value of hard work and determination first-hand.
              Jeff has built and led teams large and small across the private, public, and non-profit sectors.
              Jeff uses an approach that is predicated on developing and cultivating trusting and engaging 
              relationships, both within and without his clients’ organizations.  
          </Typography>
          <Typography color="primary" align="center" variant="body1" className={classes.moreBodyTextQuote}>
            “My goal is to help individuals and organizations perform at their very best, 
             to enjoy their work, and make a lasting difference in the world.”
          </Typography>
        </Collapse>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleClick}>{expanded ? "Show less" : "Learn more"}</Button>
      </Box>
    </>
  );
};

export default MeetJeff;